<template>
  <div class="loading h-100">

    <div class="container py-5">
      <div class="row py-5 d-flex justify-content-center">
        <div class="col-4">
          <div class="row text-center text-primary">
            <span class="container d-flex justify-content-center">
              <span><strong>CARGANDO</strong></span>
            </span>
          </div>
          <div class="row text-center text-primary mt-3">
            <span class="container d-flex justify-content-center">
              <span class="px-2" v-for="(item, key) in items" :key="key" :class="item.style">
                <i class="small fad fa-circle"></i>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      loading_data: '',
      count: '',
      items: '',
      style_in: '',
      style_out: ''
    }
  },
  watch: {
    loading(value) { 
      if(!value.is_loading) {
        this.$router.push(value.redirect)
      }
    }
  },
  computed: {
    loading() { return this.$store.getters.get_loading }
  },
  created() {
    document.title = 'Cargando...'

    this.style_in = 'animate__animated animate__fadeInDown'
    this.style_out = 'animate__animated animate__fadeOutDown'
    this.items = []
    this.count = 0

    setInterval(() => {
      this.count = this.count + 1
      switch (this.count) {
        case 1: this.items.push({ style: this.style_in }); break;
        case 2: this.items.push({ style: this.style_in }); break;
        case 3: this.items.push({ style: this.style_in }); break;

        case 4: this.items[0].style = this.style_out; break;
        case 5: this.items[1].style = this.style_out; break;
        case 6: this.items[2].style = this.style_out; break;

        case 7: 
          var redirect = this.$store.getters.get_loading.redirect
          this.$store.commit('set_loading', { is_loading: false, redirect: redirect })
      }
    }, 500);
    
  },
}
</script>